import Header from "./layout/Header";
import ComingSoon from "./pages/ComingSoon/ComingSoon";

function App() {
  return (
    <div>
      <ComingSoon />
    </div>
  );
}

export default App;
